body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #616161;
  overflow-x: show;
}

html {
  scroll-behavior: smooth
}

html.wf-active {
  font-family: sans-serif;
}

.pagebody {
  margin: 0 auto 0 auto;
  width: auto;
  max-width: 800px;
  min-width: 300px;
  text-align: center;
}

#social_icon_footer {
  padding: 0px 0;
}

#social_icon_footer a {
  color: #ffffff;
}

#social_icon_footer .social:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

#social_icon_footer .social {
  -webkit-transform: scale(0.8);
  /* Browser Variations: */
  padding: 0 8px 0 8px;
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

/*
      Multicoloured Hover Variations
  */

#social_icon_footer #social-discord:hover {
  color: #7187dc;
}

#social_icon_footer #social-tw:hover {
  color: #4099FF;
}

#social_icon_footer #social-gp:hover {
  color: #d34836;
}

#social_icon_footer #social-em:hover {
  color: #f39c12;
}

a {
  text-decoration: none;
  color: #7d4e4f
}

a:hover {
  text-decoration: none;
  color: #444444 /* #454579 */
}

.minorlink {
  text-decoration: none;
  color: #f19899
}

.minorlink:hover {
  text-decoration: none;
  color: #444444 /* #454579 */
}

.flip {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.talking .mouthidle {
  visibility: hidden;
}

.talking .mouthtalking {
  visibility: visible;
}

.idle .mouthidle {
  visibility: visible;
}

.idle .mouthtalking {
  visibility: hidden;
}

/*
@media screen and (orientation: portrait) {
  #debatedisplay {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
*/
@media screen and (orientation: landscape) {
  #rotatemessage {
    display: none
  }
}

.share-button:hover:not(:active) {
  opacity: 0.75;
}

.nowrap {
  white-space: nowrap;
}

/* ----- Montserrat ------ */

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/montserrat-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('/fonts/montserrat-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/montserrat-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/montserrat-v13-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/fonts/montserrat-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/montserrat-v13-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/montserrat-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('/fonts/montserrat-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/montserrat-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/montserrat-v13-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/fonts/montserrat-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/montserrat-v13-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* ----- RobotoMono ------ */

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Mono Light'), local('RobotoMono-Light'), 
    url('/fonts/RobotoMono.woff2') format('woff2'),
    url('/fonts/roboto-mono-v6-latin-300.woff') format('woff');;
}
